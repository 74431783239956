import React, {useReducer} from "react";
export const AgentContext = React.createContext()
const AgentContextProvider = (props) => {
    const authReducer = (agent, action) => {
        switch (action.type){
            case "save": {
                agent = action.payload.agent
                return agent
            }
            case "unsaved":
            {
                agent = false
                return agent
            }
            default:
                return agent
        }
    }
    const [agent, dispatch] = useReducer(authReducer,false)

    return(
        <AgentContext.Provider value={{agent, dispatch}}>
            {props.children}
        </AgentContext.Provider>
    )
}
export default AgentContextProvider
