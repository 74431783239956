import {Fragment, useContext} from "react";
import CarouselSlider from "../Components/CarouselSlider/CarouselSlider/CarouselSlider";
import HomeBanners from "../Containers/HomeBanners/HomeBanners";
import Statistics from "../Containers/Statistics/Statistics";
const mainSliderItems  = [
    {
        src:"https://heinrichsclub.com/club/assets/img/sliders/slide1.jpg",
        alt: "Hi Every Body"
    },
    {
        src:"https://heinrichsclub.com/club/assets/img/sliders/slide2.jpg",
        alt: "Hi Every Body"
    },
    {
        src:"https://heinrichsclub.com/club/assets/img/sliders/slide3.jpg",
        alt: "Hi Every Body"
    },
    {
        src:"https://heinrichsclub.com/club/assets/img/sliders/slide4.jpg",
        alt: "Hi Every Body"
    },
    {
        src:"https://heinrichsclub.com/club/assets/img/sliders/slide5.jpg",
        alt: "Hi Every Body"
    },
    {
        src:"https://heinrichsclub.com/club/assets/img/sliders/slide6.jpg",
        alt: "Hi Every Body"
    },
    {
        src:"https://heinrichsclub.com/club/assets/img/sliders/slide7.jpg",
        alt: "Hi Every Body"
    },
    {
        src:"https://heinrichsclub.com/club/assets/img/sliders/slide8.jpg",
        alt: "Hi Every Body"
    },
    {
        src:"https://heinrichsclub.com/club/assets/img/sliders/slide9.jpg",
        alt: "Hi Every Body"
    },
    {
        src:"https://heinrichsclub.com/club/assets/img/sliders/slide10.jpg",
        alt: "Hi Every Body"
    }
]
const HomeLayout = () => {
    return(
        <Fragment>
            <CarouselSlider sliderItems={mainSliderItems} />
            <Statistics />
            <HomeBanners />
        </Fragment>
    )
}
export default HomeLayout