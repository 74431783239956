import {Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, CircularProgress, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import {useMediaQuery} from "react-responsive";

const Counter = (props) => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let timer = null
        if (progress < props.val){
            timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= props.val ? 0 : prevProgress + 10));
            }, 150);

            return () => {
                clearInterval(timer);
            };
        }
        else{
            clearInterval(timer)
        }

    }, [progress]);
    return(
        <Card >
            <CardActionArea sx={{height: 450, textAlign:"center"}}>
                <CardMedia
                    style={{height:20, width:"100%", backgroundColor:"#37474f"}}
                />
                <Box sx={{ position: 'relative', display: 'inline-flex' ,padding:2, margin:"auto" }}>
                    <CircularProgress variant="determinate" value={progress} color={props.color} size={240} thickness={1.5}/>
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography size={50} variant="h6" component="div" color="tertiary">
                            {`${props.value} ${props.type}`}
                        </Typography>
                    </Box>
                </Box>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize:17}}>
                        {props.title}  {props.type === "Products" &&  "(Submit an Order)"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{textAlign:"justify", fontSize:isMobile? 12 : 15}}>
                        {props.content}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary" onClick={props.handler} sx={{marginTop:1}}>
                    More Info
                </Button>
                {props.type === "Products" &&  <AddShoppingCartIcon style={{float:"right"}} onClick={props.handler} />}
            </CardActions>
        </Card>
    )
}
export default Counter