import {Fragment, useContext, useRef, useState} from "react";
import {Box, Button, Modal, TableCell, TableRow, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router";
import useGetByToken from "../../hooks/useGetByToken";
import useUpdateRefreshToken from "../../hooks/useUpdateRefreshToken";
import usePostByToken from "../../hooks/usePostByToken";
import useDecryption from "../../hooks/useDecryption";
import {AuthContext} from "../../Contexts/AuthContext/AuthContext";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const Prices = (props) => {
    const [open, setOpen] = useState(false);
    const [row, setRow] = useState()
    const [count, setCount] = useState(100)
    const {verificationMessage, handleSnackClose, open:op, severity, sendRequest:postHandler, loadingStatus} = usePostByToken()
    const {sendRequest: refreshToken} = useUpdateRefreshToken()
    const {decryption} = useDecryption()
    const authContext = useContext(AuthContext)
    const handleClick = (event, row) => {
        setOpen(true)
        setRow(row)
    };
    const addToCart = (product_id, count) => {
        let id = ""
        decryption(localStorage.getItem("data"), (data) => {id = JSON.parse(data).role_id})
        const applyData = (data) => {
            if (data.status) {
                setOpen(false)
            } else if(data.result === "Refresh-Token") {
                const applyRefresh = (data) => {
                    if (data.status) {
                        authContext.dispatch({
                            type: "update-token", payload: {
                                data : {
                                    role_id: data.result.id,
                                    role: props.role,
                                    access_token: data.result.access_token,
                                    refresh_token: data.result.refresh_token}
                            }
                        })
                        addToCart(product_id, count)
                    }
                }
                refreshToken(applyRefresh).then(r => {}, error => {}).catch(e => {})
            }
        }
        const data = {
            agent_id: id,
            product_id: product_id,
            count:count,
            type:"plus",
        }
        if (count > 0) {
            postHandler({url: '/cart', data: data}, applyData).then(r => {}, error => {}).catch(e => {})
        }
    }

    const countHandler = (event) => {
        setCount(event.target.value)
    }
    const handleClose = () => setOpen(false);
    return (
        <Fragment>
            {props.pricesList
                .slice(props.page * props.rowPerPage, props.page * props.rowPerPage + props.rowPerPage)
                .map((row) => {
                    return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={(event) => handleClick(event, row)}>
                            {props.columns.map((column) => {
                                const value = row[column.id];
                                return (
                                    <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof parseInt(value) === 'number'
                                            ? new Intl.NumberFormat('de-DE').format(value)
                                            : value}
                                        {
                                            column.id === "cart_icon" ?
                                                <AddShoppingCartIcon /> : null
                                        }
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    );
                })}
            {
                open && <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="row" style={{justifyContent:"center"}}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontSize:17, marginBottom:10}}>
                                {row.product_name}
                            </Typography>
                            <TextField
                                style={{width:"90%"}}
                                label="Count"
                                id="standard-size-small"
                                defaultValue="100"
                                size="normal"
                                onChange={countHandler}
                            />
                            <Button style={{marginTop:10}} onClick={() => {
                                addToCart(row.id, count)
                                setOpen(false)
                            }}>Add To Cart</Button>
                        </div>
                    </Box>
                </Modal>
            }

        </Fragment>
    )
}
export default Prices