import useDecryption from "../hooks/useDecryption";
import {Navigate} from "react-router"
const ProtectedServiceRoute = (props) => {
    const {decryption } = useDecryption()
    let role = ""
    decryption(localStorage.getItem("data"), (data) => {
        role = JSON.parse(data).role
    })
    if(role !== "service"){
        return(
            <Navigate to={"/"} />
        )
    }
    return (
        props.children
    )
}
export default ProtectedServiceRoute