import {Card, InputLabel, MenuItem, Select, Snackbar, TextField} from "@mui/material";
import {useNavigate} from "react-router";
import {useState, useEffect, useContext, forwardRef} from "react";
import FormControl from "@mui/material/FormControl";
import useUpdateRefreshToken from "../../hooks/useUpdateRefreshToken";

import {AuthContext} from "../../Contexts/AuthContext/AuthContext";
import useDecryption from "../../hooks/useDecryption";
import MuiAlert from "@mui/material/Alert";
import CirProgress from "../../Widget/CirProgress/CirProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import usePostByToken from "../../hooks/usePostByToken";
const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const TicketForm = (props) => {
    const authContext = useContext(AuthContext)
    const {decryption} = useDecryption()
    const navigate = useNavigate()
    const [department, setDepartment] = useState(1)
    const [isErrorContent, setIsErrorContent] = useState()
    const [isErrorSubject, setIsErrorSubject] = useState()
    const [subject, setSubject] = useState()
    const [content, setContent] = useState()
    const [userId, setUserId] = useState()
    const {sendRequest: refreshToken} = useUpdateRefreshToken()
    const {verificationMessage, loadingStatus, sendRequest: postHandler, open, handleSnackClose, severity} = usePostByToken()
    useEffect(() => {
        console.log(props.id)
        setUserId(props.id)

    }, []);
    const handleChange = (event) => {
        setDepartment(event.target.value)
    }
    const subjectHandler = (event) => {
        event.target.value.length < 3 ? setIsErrorSubject(true) : setIsErrorSubject(false)
        setSubject(event.target.value)
    }
    const contentHandler = (event) => {
        event.target.value.length < 5 ? setIsErrorContent(true) : setIsErrorContent(false)
        setContent(event.target.value)
    }

    const postTicket = () => {
        let id = ""
        decryption(localStorage.getItem("data"), (data) => {id = JSON.parse(data).role_id})
        const applyData = (data) => {
            if (data.status) {
                if(props.role === "agent"){
                    navigate("/agent-dashboard/tickets")
                }else if(props.role === "customer"){
                    navigate("/customer-dashboard/tickets")
                }else {
                    
                }

            } else if(data.result === "Refresh-Token") {
                const applyRefresh = (data) => {
                    if (data.status) {
                        authContext.dispatch({
                            type: "update-token", payload: {
                                data : {
                                    role_id: data.result.id,
                                    role: props.role,
                                    access_token: data.result.access_token,
                                    refresh_token: data.result.refresh_token}
                            }
                        })
                        postTicket()
                    }
                }
                refreshToken(applyRefresh).then(r => {}, error => {}).catch(e => {})
            }
        }
        const data = {
            user_id: userId,
            subject: subject,
            department_id:department,
            content:content,
            role: props.role,
            status: 1,
        }
        if (!(isErrorSubject || isErrorContent )) {
            postHandler({url: '/ticket', data: data}, applyData).then(r => {}, error => {}).catch(e => {})
        }
    }

    return(
        <div className="row container-fluid g-0" style={{marginTop: 70, marginBottom: 6, justifyContent:"center", width:"90%", marginRight:"auto", marginLeft:"auto"}}>
            <Card sx={{marginTop:2, marginBottom:3, marginRight:"auto", marginLeft:"auto", width:"100%", padding:1}}>
                <div className="row" style={{marginBottom:30}}>
                    <div className="col-sm-4">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Choice Department</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={department}
                                label="Choice Department"
                                onChange={handleChange}
                            >
                                <MenuItem value={1}>Warranty and Service</MenuItem>
                                <MenuItem value={2}>Account Management</MenuItem>
                                <MenuItem value={3}>Technical Support</MenuItem>
                                <MenuItem value={4}>CEO's Office</MenuItem>
                                <MenuItem value={5}>Order and Delivery</MenuItem>
                                <MenuItem value={6}>Billing and Payments</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-sm-8">
                        <TextField id="outlined-basic" label="Subject" variant="outlined" style={{width:"100%"}} onChange={subjectHandler}/>
                    </div>
                </div>
                <div className="row" style={{padding:20}}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Content"
                        multiline
                        defaultValue="Content"
                        rows={20}
                        onChange={contentHandler}
                    />

                </div>
                <LoadingButton
                    size="medium" sx={{margin:"auto"}} onClick={postTicket}
                    loadingIndicator={<CirProgress width={32} height={32}/>}
                    loading={loadingStatus === "loading"}
                >
                    Send
                </LoadingButton>

            </Card>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: 400 }}>
                    {verificationMessage}
                </Alert>
            </Snackbar>
        </div>

    )
}
export default TicketForm