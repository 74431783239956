import {
    Box, Button,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, TextField, Typography
} from "@mui/material";
import {Fragment, useContext, useState} from "react";
import {useNavigate} from "react-router";
import {type} from "@testing-library/user-event/dist/type";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReceiptIcon from '@mui/icons-material/Receipt';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import usePostByToken from "../../hooks/usePostByToken";
import useDeleteByToken from "../../hooks/useDeleteByToken";
import useUpdateRefreshToken from "../../hooks/useUpdateRefreshToken";
import useDecryption from "../../hooks/useDecryption";
import {AuthContext} from "../../Contexts/AuthContext/AuthContext";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const TableTemplate = (props) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [row, setRow] = useState()
    const [issueContent, setIssueContent] = useState("")
    const {verificationMessage, handleSnackClose, open:op, severity, sendRequest:postHandler, loadingStatus} = usePostByToken()
    const {sendRequest: refreshToken} = useUpdateRefreshToken()
    const {decryption} = useDecryption()
    const authContext = useContext(AuthContext)
    const handleClick = (event, id, row) => {
        switch (props.type){
            case "guarantee":
                break;
            case "ticket":
                if(props.role==="agent"){
                    navigate("/agent-dashboard/tickets/" + id,{
                        state :{
                            ...row
                        }
                    })
                }else if(props.role === "customer"){
                    navigate("/customer-dashboard/tickets/" + id,{
                        state :{
                            ...row
                        }
                    })
                }else {

                }

                break;
            case "order":
                navigate("/agent-dashboard/order/" + id,{
                    state :{
                        ...row
                    }
                })
                break;
            case "issue":
                props.onSelected(row)
                break;
            default:
                break;
        }

    };
    const receiptHandler = () => {
        console.log("receipt")
    }
    const issueHandler = (row) => {
        setOpen(true)
        setRow(row)
    }
    const handleClose = () => setOpen(false);
    const issueContentHandler = (event) => {
        setIssueContent(event.target.value)
    }

    const reportIssue = () => {
        let id = ""
        decryption(localStorage.getItem("data"), (data) => {id = JSON.parse(data).role_id})
        const applyData = (data) => {
            console.log(data)
            if (data.status) {
                setOpen(false)
            } else if(data.result === "Refresh-Token") {
                const applyRefresh = (data) => {
                    if (data.status) {
                        authContext.dispatch({
                            type: "update-token", payload: {
                                data : {
                                    role_id: data.result.id,
                                    role: props.role,
                                    access_token: data.result.access_token,
                                    refresh_token: data.result.refresh_token}
                            }
                        })
                        reportIssue()
                    }
                }
                refreshToken(applyRefresh).then(r => {}, error => {}).catch(e => {})
            }
        }
        const data = {
            barcode: row.barcode,
            customer_id: row.customer_id,
            product_model:row.product_model,
            subject: issueContent,
            status_id:1
        }
        console.log(data)
        if (issueContent.length > 0) {
            postHandler({url: '/issue', data: data}, applyData).then(r => {}, error => {console.log(error)}).catch(e => {console.log(e)})
        }
    }
    const {columns, rowsPerPage,  rows, page, emptyRows, allRowCount, handleChangePage, handleChangeRowsPerPage, TablePaginationActions} = props
    return(
        <Fragment>
            <TableContainer sx={{ maxHeight: 500 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                        ).map((row) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={(event) => handleClick(event, row.id, row)}>
                                {columns.map((column) => {

                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            { column.id==="start_at" ?
                                                (540 -  Math.ceil( ((new Date()).getTime() - (new Date(row.start_at)).getTime()) / (1000 * 3600 * 24))) :
                                                column.format && typeof parseInt(value) === 'number' ?
                                                    new Intl.NumberFormat('de-DE').format(value) :
                                                    value}
                                            {
                                                props.type === "order" && column.id === "cart_icon" ?
                                                    <ShoppingCartIcon /> : null
                                            }
                                            {
                                                props.type === "guarantee" && column.id === "receipt"  ?
                                                    <ReceiptIcon onClick={receiptHandler} /> : null
                                             }
                                            {
                                                props.type === "guarantee" && props.role === "customer" && column.id === "issue"?
                                                    <BrokenImageIcon onClick={() => {issueHandler(row)}} /> : null
                                            }
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 100  /*, { label: 'All', value: -1 }*/]}
                colSpan={6}
                count={allRowCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: {
                        'aria-label': 'rows per page',
                    },
                    native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
            {
                open && <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="row" style={{justifyContent:"center"}}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontSize:17, marginBottom:15, marginLeft: 30}}>
                                {row.barcode}
                            </Typography>
                            <TextField
                                style={{width:"95%"}}
                                label="Issue"
                                id="standard-size-small"
                                defaultValue=""
                                size="normal"
                                rows={10}
                                multiline
                                onChange={issueContentHandler}
                            />
                            <Button style={{marginTop:10}} onClick={() => {
                                reportIssue()
                            }}>Report</Button>
                        </div>
                    </Box>
                </Modal>
            }

        </Fragment>
    )
}
export default TableTemplate