import {AppBar, Box, Button, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {DrawerContext} from "../../Contexts/DrawerContext/DrawerContext";
import {Fragment, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {AuthContext} from "../../Contexts/AuthContext/AuthContext";
import {AgentContext} from "../../Contexts/AgentContext/AgentContext";
import useDecryption from "../../hooks/useDecryption";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SendIcon from '@mui/icons-material/Send';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {useMediaQuery} from "react-responsive";
const TopAppBar = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const authContext = useContext(AuthContext)
    const agentContext = useContext(AgentContext)
    const navigate = useNavigate()
    const drawerContext = useContext(DrawerContext);
    const [role, setRole] = useState("")
    const {decryption}  = useDecryption()
    const toggleDrawer =() => {
        drawerContext.dispatch({type:"toggleDrawer"})
    }
    const loginHandler = () => {
        navigate("/login")
    }
    const logoutHandler = () => {
        setRole("")
        authContext.dispatch({type:"logout"})
        agentContext.dispatch({type:"unsaved"})
        navigate("/", {replace:true})
    }

    useEffect(() => {
        if(localStorage.getItem("data") !== null){
            decryption(localStorage.getItem("data"), (data) => {
                setRole(JSON.parse(data).role)
            })
        }
    })
    const ticketHandler = () => {
        navigate("/agent-dashboard/tickets")
    }
    const cartHandler = () => {
        navigate("/agent-dashboard/cart")
    }

    const orderHandler = () => {
        navigate("/agent-dashboard/orders")
    }

    return(
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton onClick={toggleDrawer}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize:isMobile?15:20 }}>
                        Heinrichs Customers Club
                    </Typography>
                    {
                        role === "agent"?
                            <Fragment>
                                <ShoppingCartIcon sx={{marginRight:2}} onClick={cartHandler}/>
                                <SendIcon sx={{marginRight:2}} onClick={ticketHandler}/>
                                <ListAltIcon sx={{marginRight:2}} onClick={orderHandler} />
                            </Fragment>:
                            null
                    }
                    {
                        role === "agent" || role === "service" || role === "customer" ?
                            <Button color="inherit" onClick={logoutHandler}>Logout</Button>
                            : <Button color="inherit" onClick={loginHandler}>Login</Button>
                    }
                </Toolbar>
            </AppBar>
        </Box>
    )
}
export default TopAppBar