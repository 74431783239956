import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import classes from "./CarouselSlider.module.css"
import CarouselSliderItem from "../CarouselSliderItem/CarouselSliderItem";
import {useEffect, useState} from "react";
const CarouselSlider = (props) => {
    const [imageSliders, setImageSlider] = useState(props.sliderItems)
    useEffect(() => {
        setImageSlider(props.sliderItems)
    }, []);


    return (
        <div className={classes.container}>
            <Carousel  infiniteLoop={true} showThumbs={false} dynamicHeight={true} transitionTime={1000} autoPlay={true} interval={4000}>
                {
                    imageSliders.map(imageSlider => <CarouselSliderItem
                        source={imageSlider.src}
                        alt={imageSlider.alt}
                    />)
                }
            </Carousel>
        </div>

    );
}
export default CarouselSlider