import Prices from "../../Components/Prices/Prices";
import {Box, CircularProgress, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";
import {forwardRef, useEffect, useState, Fragment, useContext} from "react";
import "./Prices.css"
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import MuiAlert from "@mui/material/Alert";
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import PropTypes from "prop-types";
import {AuthContext} from "../../Contexts/AuthContext/AuthContext";
import useGetByToken from "../../hooks/useGetByToken";
import useUpdateRefreshToken from "../../hooks/useUpdateRefreshToken";

import TableTemplate from "../../Components/TableTemplate/TableTemplate";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page > Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page > Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};
const columns = [
    { id: 'id', label: 'ID', minWidth: 15 },
    { id: 'department', label: 'Department', minWidth: 50 },
    { id: 'subject', label: 'Subject', minWidth: 200 },
    {id: 'status', label: 'Status', minWidth: 50, align: 'right'},
    {id: 'last_update', label: 'Last Update', minWidth: 50, align: 'right'},
];
const TicketsList = (props) => {
    const authContext = useContext(AuthContext)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0)
    const [rows, setRows] = useState([])
    const {verificationMessage, handleSnackClose, open, severity, sendRequest:getData, loadingStatus} = useGetByToken()
    const {sendRequest: refreshToken} = useUpdateRefreshToken()
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const getCountData = async () => {
        const applyData = async (data) => {
            if (data.status) {
                setCount(data.result.count)
                await getAllData()
            } else if (data.result === "Refresh-Token") {
                const applyRefresh = (data) => {
                    if (data.status) {
                        authContext.dispatch({
                            type: "update-token", payload: {
                                data : {
                                    role_id: data.result.id,
                                    role: props.role,
                                    access_token: data.result.access_token,
                                    refresh_token: data.result.refresh_token
                                },
                                exp_time:new Date(new Date().getTime() + 900000)
                            }
                        })
                        getCountData()
                    }
                }
                refreshToken(applyRefresh).then(r => {
                }, error => {
                }).catch(e => {
                })
            }
        }
        await getData({url: `/ticket?page=-1`}, applyData)
    }

    const getAllData = async () => {
        const applyData = (data) => {
            if (data.status) {
                setRows([...data.result])
            } else if (data.result === "Refresh-Token") {
                const applyRefresh = (data) => {
                    if (data.status) {
                        authContext.dispatch({
                            type: "update-token", payload: {
                                data : {
                                    role_id: data.result.id,
                                    role: props.role,
                                    access_token: data.result.access_token,
                                    refresh_token: data.result.refresh_token}
                            }
                        })
                        getAllData()
                    }
                }
                refreshToken(applyRefresh).then(r => {}, error => {}).catch(e => {})
            }
        }
        await getData({url: `/ticket?page=1`}, applyData)
    }

    useEffect(() => {
        getCountData().then(r => () => {
        }).catch((e) => {
        })
    }, [])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return(
        <Fragment>
            {
                loadingStatus === "loading"?
                    <Box sx={{ display: 'flex', width:"100%", height:"100%",paddingLeft:"48%", paddingTop:"135px", position:"absolute", zIndex:100 }}>
                        <CircularProgress />
                    </Box>:
                    null
            }
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableTemplate
                    role={props.role}
                    type="ticket"
                    columns={columns}
                    rowsPerPage={rowsPerPage}
                    rows={rows}
                    page={page}
                    emptyRows={emptyRows}
                    allRowCount={count}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    TablePaginationActions={TablePaginationActions}
                />
            </Paper>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: 400 }}>
                    {verificationMessage}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}
export default TicketsList