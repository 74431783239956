import {Link} from "@mui/material";
import {memo} from "react";

const CopyRight = () => {
    return(
        <div className="row container-fluid g-0" style={{ width:"100%",backgroundColor:"#263238", margin:(0, "auto")}}>
            <div  style={{ backgroundColor:"#263238", marginBottom:5, marginTop: 5, textAlign:"center"}} className="col-sm-12" >
                <div>
                    <Link sx={{color:"#ffffff", fontSize:10, textAlign:"center"}} color="tertiary" target="_blank" underline="none" size="small" href="#">@ 2023 Heinrich`s Company. All rights reserved.</Link>
                </div>
            </div>
        </div>
    )
}
export default memo(CopyRight)