import GuarantiesRegistrationForm from "../../Components/GuarantiesRegistrationForm/GurantiesRegistrationForm";

const GuarantiesRegistration = () => {
    return(
        <div className="row" style={{marginTop: 70, marginBottom: 6}}>
            <div className="col-sm"  style={{marginBottom:6}}>
                <GuarantiesRegistrationForm />
            </div>
        </div>
    )
}
export default GuarantiesRegistration