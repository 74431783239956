import LinksFooter from "../../Components/LinksFooter/LinksFooter";
import SocialFooter from "../../Components/SocialFooter/SocialFooter";
import AboutFooter from "../../Components/AboutFooter/AboutFooter";
import CopyRight from "../../Components/CopyRight/CopyRight";
import {memo} from "react"
const Footer = () => {
    return(
        <div style={{top: 'auto', bottom: 0, position:"static"}}>
            <SocialFooter />
            <AboutFooter />
            <LinksFooter />
            <CopyRight />
        </div>
    )
}
export default memo(Footer)