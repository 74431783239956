import PriceContainer from "../Containers/PriceContainer/PriceContainer";
import {AuthContext} from "../Contexts/AuthContext/AuthContext";
import {Fragment, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import useDecryption from "../hooks/useDecryption";
import {useDispatch, useSelector} from "react-redux";
import {roleSliceActions} from "../Store/roleSlice";
import TicketForm from "../Components/TicketForm/TicketForm";

const TicketFormLayout = () => {

    const navigate = useNavigate()
    const {decryption} = useDecryption()
    const dispatch = useDispatch()
    const roleStore = useSelector(state => state.roleSlice.role)
    const [id, setId] = useState(false)
    useEffect(() => {
        if(localStorage.getItem("data") !== null){
            let role = ""
            decryption(localStorage.getItem("data"), (data) => {
                //dispatch(roleSliceActions.setRole("agent"))
                role = JSON.parse(data).role
                setId(JSON.parse(data).role_id)

            })
            if(localStorage.getItem("data") === null || (role !== "agent" && role !== "customer")){
                navigate("/")
            }
        }
    }, [])
    return(

        <Fragment>
            {roleStore !== "customer" && roleStore !== "agent" ? navigate("/") :id &&  <TicketForm role={roleStore} id={id} /> }
        </Fragment>

    )
}
export default TicketFormLayout