import {Divider, Link, Typography} from "@mui/material";
import {memo} from "react";

const BasicCard = (props) => {
    return(
            <div>
                <Typography variant="p" component="div" color="#ffffff" sx={{fontWeight:"bold", fontSize:20}}>
                    {props.title}
                </Typography>
                {
                    props.content.toString().trim().length > 0 ?
                    <Typography sx={{ fontSize: 13, color: "#000000", textAlign:"justify" }} color="text.secondary" >
                        {props.content}
                    </Typography> : null
                }

                {
                    props.links.length > 0 ?
                        <Divider  sx={{width:"100%", marginTop: 0.7, marginBottom: 0.5, marginRight:"auto", marginLeft:"auto", borderColor: "#000000"}}/> : null
                }
                {
                    props.links.length > 0 ?
                    props.links.map((link, index) =>
                        <div key={index}>
                            <Link
                                sx={{color:"#000000", fontSize:15}} color="tertiary" target="_blank" underline="none" size="small" href={link.url}>{link.title}
                            </Link>
                        </div>
                    ) : null
                }

            </div>
    )
}
export default memo(BasicCard)