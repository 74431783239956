import {forwardRef, Fragment, useContext} from "react";
import {Box,Button,Card,CardActions,CardContent,CircularProgress,Divider, Snackbar,TextField,Typography} from "@mui/material";
import {useState} from "react";
import MuiAlert from "@mui/material/Alert";
import {AuthContext} from "../../Contexts/AuthContext/AuthContext";
import useUpdateRefreshToken from "../../hooks/useUpdateRefreshToken";
import usePatchByToken from "../../hooks/usePatchByToken";
import LoadingButton from "@mui/lab/LoadingButton";
import CirProgress from "../../Widget/CirProgress/CirProgress";
import useDecryption from "../../hooks/useDecryption";
const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GuarantiesRegistrationForm = () => {
    const authContext = useContext(AuthContext)
    const [isErrorBarcode, setIsErrorBarcode] = useState(true)
    const [barcode, setBarcode] = useState("")

    const {sendRequest: refreshToken} = useUpdateRefreshToken()
    const {verificationMessage, sendRequest: patchData, severity, handleSnackClose, open, loadingStatus} = usePatchByToken()
    const {decryption}  = useDecryption()
    const barcodeHandler = (event) => {
        if(event.target.value.length < 11){
            setIsErrorBarcode(true)
        }else{
            setIsErrorBarcode(false)
        }
        setBarcode(event.target.value)
    }

    const emptyInputs = () => {
        setBarcode("")
    }
    const registerGuarantee = () => {
        let id = ""
        decryption(localStorage.getItem("data"), (data) => {id = JSON.parse(data).role_id})
        const applyData = (data) => {
            if (data.status) {
                emptyInputs()
            } else if(data.result === "Refresh-Token"){
                const applyRefresh = (data) => {
                    if (data.status) {
                        authContext.dispatch({
                            type: "update-token", payload: {
                                data: {
                                    role_id: data.result.id,
                                    role: "agent",
                                    access_token: data.result.access_token,
                                    refresh_token: data.result.refresh_token
                                }
                            }
                        })
                        registerGuarantee()
                    }
                }
                refreshToken(applyRefresh).then(r => {}, error => {}).catch(e => {})
            }
        }

        if (!(isErrorBarcode)) {
            const data = {
                barcode: barcode,
                registrar_id:id
            }
            patchData({url:'/guarantee/' + id, data:data}, applyData).then(r => {}, error => {}).catch(e => {})
        }

    }

    return(
        <Fragment>
            <Card sx={{marginRight:"auto", marginLeft:"auto", width:600, padding:1}}>
                <CardContent>
                    <Typography variant="p" component="div" color="#000000" sx={{fontStyle:"italic", fontSize:20, marginBottom:2}}>
                        Register A Guarantee
                    </Typography>
                    <Divider sx={{borderColor:"#bd04f6", borderWidth: 5}}/>
                    <TextField sx={{width:"90%", margin:2}}
                               error={isErrorBarcode}
                               id="standard-helperText"
                               label="Barcode"
                               helperText="must be greater than 11 char"
                               variant="standard"
                               onChange={barcodeHandler}
                               value={barcode}
                               disabled={loadingStatus === "loading"}
                    />

                </CardContent>
                <CardActions sx={{padding:"auto"}}>
                    <LoadingButton
                        size="medium" sx={{margin:"auto"}} onClick={registerGuarantee}
                        loadingIndicator={<CirProgress width={32} height={32}/>}
                        loading={loadingStatus === "loading"}
                    >
                        Register Guarantee
                    </LoadingButton>
                </CardActions>
                <Snackbar open={open} autoHideDuration={4000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity={severity} sx={{ width: 400 }}>
                        {verificationMessage}
                    </Alert>
                </Snackbar>
            </Card>
        </Fragment>
    )
}
export default GuarantiesRegistrationForm