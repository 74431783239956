import Counter from "../../Widget/Counter/Counter";
import {Fragment, useEffect, useState} from "react";
import classes from "./Statistics.module.css";
import useGet from "../../hooks/useGet";

const guaranties_content = "every product comes with our commitment to quality and durability. The number displayed here represents the countless guarantees we've registered, ensuring our customers always have peace of mind".substring(0, 160) + "..."
const customer_content = "At Heinrichs company, Each number here is not just a statistic, but a valued member of our Heinrichs family. We're proud and humbled by the trust placed in us by so many individuals over the years".substring(0, 160) + "..."
const agents_content = "Behind every successful product lies the relentless effort of our agents. This count is a testament to the vast network of dedicated individuals who represent the Heinrichs brand every day".substring(0, 160) + " ..."
const sold_content = "This impressive count showcases the legacy of Heinrichs. Every number here is a product that has found its place in someone's home, enhancing their daily life with unparalleled quality and innovation".substring(0, 160) + "..."

const Statistics = () => {
    const [customerCount, setCustomerCount] = useState(0)
    const [guaranteeCount, setGuaranteeCount] = useState(0)
    const [soldCount, setSoldCount] = useState(0)
    const [agentCount, setAgentCount] = useState(0)

    const {verificationMessage, severity, open, sendRequest: getData, loadingStatus, handleSnackClose} = useGet()

    const getCountData =  () => {
        const applyData = (data) => {
            if (data.status) {
                setCustomerCount(data.result.ccount)
                setGuaranteeCount(data.result.gcount)
                setAgentCount(data.result.acount)
                setSoldCount(data.result.scount)
            }
        }
        getData({url: `/statistics.php`}, applyData).then(r => {}, error => {console.log(error)}).catch(e => {})
    }
    useEffect(() => {
        getCountData()
    }, [])
    const guaranteeHandler = () => {
        console.log("Home Guarantee Clicked")
    }
    const agentHandler = () => {
        console.log("Home Agent Clicked")
    }
    const customerHandler = () => {
        console.log("Home customer Clicked")
    }
    const soldHandler = () => {
        console.log("Home Sold Clicked")
    }
    return(
        <Fragment>
            <div style={{marginTop: 30, backgroundColor:"#f5f5f5"}} className={classes.container}>
                <div style={{textAlign:"center", marginTop:20 }}><h3 style={{color:"#000000"}}>Statistics of Heinrichs Customer, Agents, ...</h3></div>
                <div className="row" style={{margin: 20}}>
                    <div className="col-sm" style={{margin: 10}}>
                        <Counter val={40} value={guaranteeCount} color={"secondary"} type={"Guaranties"}
                                 title="Secured Peace of Mind" content={guaranties_content} handler={guaranteeHandler}/>
                    </div>
                    <div className="col-sm" style={{margin: 10}}>
                        <Counter val={35} value={customerCount} color={"secondary"} type={"Customers"}
                                 title="Growing Heinrichs Family" content={customer_content} handler={customerHandler}/>
                    </div>
                    <div className="col-sm" style={{margin: 10}}>
                        <Counter val={45} value={agentCount} color={"secondary"} type={"Agents"}
                                 title="Our Pillars of Strength" content={agents_content} handler={agentHandler}/>
                    </div>
                    <div className="col-sm" style={{margin: 10}}>
                        <Counter val={65} value={soldCount} color={"secondary"} type={"Sold"}
                                 title="Legacy of Excellence" content={sold_content} handler={soldHandler}/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Statistics