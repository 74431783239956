import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import AuthContextProvider from "./Contexts/AuthContext/AuthContext";
import AgentContextProvider from "./Contexts/AgentContext/AgentContext";
import DrawerContextProvider from "./Contexts/DrawerContext/DrawerContext";
import store from "./Store";
import {Provider} from "react-redux"
import CustomerContextProvider from "./Contexts/CustomerContext/CustomerContext";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <AuthContextProvider>
            <AgentContextProvider>
                <CustomerContextProvider>
                    <DrawerContextProvider>
                        <App />
                    </DrawerContextProvider>
                </CustomerContextProvider>
            </AgentContextProvider>
        </AuthContextProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
