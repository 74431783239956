import Prices from "../../Components/Prices/Prices";
import {Box, CircularProgress, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";
import {forwardRef, useEffect, useState, Fragment, useContext} from "react";
import "./Prices.css"
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import MuiAlert from "@mui/material/Alert";
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import PropTypes from "prop-types";
import {AuthContext} from "../../Contexts/AuthContext/AuthContext";
import useGetByToken from "../../hooks/useGetByToken";
import useUpdateRefreshToken from "../../hooks/useUpdateRefreshToken";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page > Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page > Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};
const columns = [
    { id: 'id', label: 'ID', minWidth: 15 },
    { id: 'product_name', label: 'Product Name', minWidth: 100 },
    { id: 'product_model', label: 'Product Model', minWidth: 50 },
    {id: 'product_dollar_price', label: 'Dollar Price', minWidth: 70, align: 'right', format: (value) => value.toLocaleString('en-US'),},
    {id: 'product_wholesale_price', label: 'Wholesale Price', minWidth: 70, align: 'right', format: (value) => value.toLocaleString('en-US'),},
    {id: 'product_retail_price', label: 'Retail Price', minWidth: 70, align: 'right', format: (value) => value.toLocaleString('en-US'),},
    {id: 'cart_icon', label: 'Add To Cart', minWidth: 50, align: 'center'},
];
const PriceContainer = () => {
    const authContext = useContext(AuthContext)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0)
    const [rows, setRows] = useState([])
    const {verificationMessage, handleSnackClose, open, severity, sendRequest:getData, loadingStatus} = useGetByToken()
    const {sendRequest: refreshToken} = useUpdateRefreshToken()

    const getCountData = async () => {
        const applyData = async (data) => {
            if (data.status) {
                setCount(data.result.count)
                await getAllData()
            } else if (data.result === "Refresh-Token") {
                const applyRefresh = (data) => {
                    if (data.status) {
                        authContext.dispatch({
                            type: "update-token", payload: {
                                data : {
                                    role_id: data.result.id,
                                    role: "agent",
                                    access_token: data.result.access_token,
                                    refresh_token: data.result.refresh_token
                                }
                            }
                        })
                        getCountData()
                    }
                }
                refreshToken(applyRefresh).then(r => {
                }, error => {
                }).catch(e => {
                })
            }
        }
        await getData({url: `/product?page=-1`}, applyData)
    }

    const getAllData = async () => {
        const applyData = (data) => {
            if (data.status) {
                setRows([...data.result])
            } else if (data.result === "Refresh-Token") {
                const applyRefresh = (data) => {
                    if (data.status) {
                        authContext.dispatch({
                            type: "update-token", payload: {
                                role_id: data.result.id,
                                role: "agent",
                                access_token: data.result.access_token,
                                refresh_token: data.result.refresh_token
                            }
                        })
                        getAllData()
                    }
                }
                refreshToken(applyRefresh).then(r => {}, error => {}).catch(e => {})
            }
        }
        await getData({url: `/product?page=1`}, applyData)
    }

    useState(() => {
        getCountData().then(r => () => {
        }).catch((e) => {
        })
    }, [])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return(
        <div className="row container-fluid g-0" style={{marginTop: 70, marginBottom: 6, justifyContent:"center"}}>
            <Fragment>
                {
                    loadingStatus === "loading"?
                        <Box sx={{ display: 'flex', width:"100%", height:"100%",paddingLeft:"48%", paddingTop:"135px", position:"absolute", zIndex:100 }}>
                            <CircularProgress />
                        </Box>:
                        null
                }
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Prices pricesList={rows} columns={columns} page={page} rowPerPage={rowsPerPage}/>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="Card"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={severity} sx={{ width: 400 }}>
                    {verificationMessage}
                </Alert>
            </Snackbar>
            </Fragment>
        </div>
    )
}
export default PriceContainer