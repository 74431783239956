import React, {useReducer} from "react";
export const CustomerContext = React.createContext()
const CustomerContextProvider = (props) => {
    const authReducer = (customer, action) => {
        switch (action.type){
            case "save": {
                customer = action.payload.customer
                return customer
            }
            case "unsaved":
            {
                customer = false
                return customer
            }
            default:
                return customer
        }
    }
    const [customer, dispatch] = useReducer(authReducer,false)

    return(
        <CustomerContext.Provider value={{customer, dispatch}}>
            {props.children}
        </CustomerContext.Provider>
    )
}
export default CustomerContextProvider
