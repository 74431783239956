import {Typography} from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {memo} from "react";
const SocialFooter = () => {
    return(
        <div className="row container-fluid g-0" style={{ width:"100%",backgroundColor:"#cfd8dc", margin:(0, "auto")}}>
            <div className="col-sm" style={{marginTop: 5, marginBottom: 5}}>
                <Typography sx={{ textAlign:"center", fontSize: 20, color: "#000000", paddingTop: 2, paddingBottom:2 }} color="text.secondary" >
                    Follow Us in Social Network
                </Typography>
            </div>
            <div className="col-sm">
                <div className="row container-fluid g-0" style={{justifyContent:"center"}} >
                    <FacebookIcon sx={{fontSize:60, color:"#000000"}} />
                    <InstagramIcon sx={{fontSize:60, color:"#000000"}} />
                    <TwitterIcon sx={{fontSize:60, color:"#000000"}} />
                    <YouTubeIcon sx={{fontSize:60, color:"#000000"}} />
                    <WhatsAppIcon sx={{fontSize:60, color:"#000000"}} />
                </div>
            </div>
        </div>
    )
}
export default memo(SocialFooter)