import {Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography} from "@mui/material";

const HomeBannerCard = (props) => {
    return (
        <Card sx={{marginTop: 2}}>
            <CardActionArea sx={{height: 280}}>
                <CardMedia
                    component="img"
                    height="140"
                    image={props.img_url}
                    alt={props.img_alt}
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" style={{fontSize:16}}>
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{textAlign:"justify"}}>
                        {props.content}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary">
                    Share
                </Button>
            </CardActions>
        </Card>
    );
}
export default HomeBannerCard