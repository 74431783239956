import React, {useCallback, useEffect, useReducer, useState} from "react";
import useEncryption from "../../hooks/useEncryption";
import * as CryptoJS from "crypto-js";

export const AuthContext = React.createContext()

let logoutTimer;

const calculateRemainingTime = (expirationTime) => {
    const currenTime = new Date().getTime()
    const adjExpirationTime = new Date(expirationTime).getTime()
    return adjExpirationTime - currenTime
}
const retrieveStoredToken = () => {
    let plainText = ""
    if(localStorage.getItem("exp_time") != null)
    {
        const secretKey = process.env.REACT_APP_KEY
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem('exp_time'), secretKey )
        plainText = bytes.toString(CryptoJS.enc.Utf8)
    }
    const data = localStorage.getItem("data")
    const remainingTime = calculateRemainingTime(plainText);
    if (remainingTime <= 10000) {
        localStorage.removeItem("data")
        localStorage.removeItem("exp_time")
        return null;
    }

    return {
        data : data,
        duration: remainingTime,
    };
}
const AuthContextProvider = (props) => {
    const [tokenData, setTokenData] = useState(retrieveStoredToken())
    const {encryption} = useEncryption()
    const logoutHandler = useCallback(() => {
        localStorage.removeItem("data")
        localStorage.removeItem("exp_time")
        if (logoutTimer) {
            clearTimeout(logoutTimer);
        }
    }, [])
    const authReducer = (state, action) => {
        switch (action.type){
            case "login": {
                encryption(JSON.stringify(action.payload.data), (data) => {localStorage.setItem("data", data)})
                encryption(action.payload.exp_time, (data) => {localStorage.setItem("exp_time", data)})
                setTokenData(retrieveStoredToken())
                const remainTime = calculateRemainingTime(action.payload.exp_time)
                logoutTimer = setTimeout(logoutHandler,remainTime)
                state = {
                    id:action.payload.data.role_id,
                    role:action.payload.data.role
                }
                return state
            }
            case "update-token":
            {
                encryption(JSON.stringify(action.payload.data), (data) => {localStorage.setItem("data", data)})
                setTokenData(retrieveStoredToken())
                state = {
                    id:action.payload.data.role_id,
                    role:action.payload.data.role
                }
                return state
            }
            case "logout":
            {
                localStorage.removeItem("data")
                localStorage.removeItem("exp_time")

                state = {
                    id:0,
                    role:""
                }
                if (logoutTimer) {
                    clearTimeout(logoutTimer);
                }
                return state

            }
            default:
                return state
        }
    }

    useEffect(() => {
        if(tokenData){
            logoutTimer = setTimeout(logoutHandler, tokenData.duration);
        }
    }, [tokenData, logoutHandler])

    const [loginStatus, dispatch] = useReducer(authReducer,{id:0, role:""})
    return(
        <AuthContext.Provider value={{loginStatus, dispatch}}>
            {props.children}
        </AuthContext.Provider>
    )
}
export default AuthContextProvider
