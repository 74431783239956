import './App.css';
import {BrowserRouter, Route} from "react-router-dom";
import {Routes, Navigate} from "react-router";
import HomeLayout from "./Layouts/HomeLayout";
import {createTheme, ThemeProvider} from "@mui/material";
import Header from "./Containers/Header/Header";
import Footer from "./Containers/Footer/Footer";
import LeftDrawer from "./Containers/LeftDrawer/LeftDrawer";
import  {AuthContext} from "./Contexts/AuthContext/AuthContext";
import {lazy, useContext, useEffect, Suspense, useState} from "react";
import Loading from "./Widget/Loading/Loading";
import useDecryption from "./hooks/useDecryption";
import ProtectedAgentRoute from "./ProtectedRoute/ProtectedAgentRoute";
import ProtectedServiceRoute from "./ProtectedRoute/ProtectedServiceRoute";
import ProtectedCustomerRoute from "./ProtectedRoute/ProtectedCustomerRoute";
import GuarantiesRegistrationLayout from "./Layouts/GuarantiesRegistrationLayout";
import TicketsLayout from "./Layouts/TicketsLayout";
import TicketFormLayout from "./Layouts/TicketFormLayout";
import TicketContentLayout from "./Layouts/TicketContentLayout";



const LoginPageLayout  = lazy(() => import("./Layouts/LoginPageLayout"));
const SignupLayout = lazy(() => import("./Layouts/SignupLayout"))  ;
const LoginByOTPLayout = lazy(() => import("./Layouts/LoginByOTPLayout")) ;
const WelcomeLayout = lazy(() => import("./Layouts/WelcomeLayout")) ;
const AgentDashboardLayout = lazy(() => import("./Layouts/AgentDashboardLayout")) ;
const BarcodeLayout = lazy(() => import("./Layouts/BarcodeLayout")) ;
const CatalogLayout = lazy(() => import("./Layouts/CatalogLayout")) ;
const PriceLayout = lazy(() => import( "./Layouts/PriceLayout"));
const CustomerDashboardLayout = lazy(() => import("./Layouts/CustomerDashboardLayout")) ;
const AgentInformationLayout = lazy(() => import("./Layouts/AgentInformationLayout")) ;
const CustomerInformationLayout = lazy(() => import("./Layouts/CustomerInformationLayout")) ;
const GuaranteeActivationLayout = lazy(() => import("./Layouts/GuaranteeActivationLayout")) ;
const GuarantiesListLayout = lazy(() => import("./Layouts/GuarantiesListLayout")) ;
const CertificateLayout = lazy(() => import("./Layouts/CertificateLayout")) ;
const ServiceLoginLayout = lazy(() => import("./Layouts/ServiceLoginLayout")) ;
const ServiceDashboardLayout = lazy(() => import("./Layouts/ServiceDashboardLayout")) ;
const CartLayout = lazy(() => import("./Layouts/CartLayout")) ;
const ModifyOrdersLayout = lazy(() => import("./Layouts/ModifyOrdersLayout" ))
const OrderLayout = lazy(() => import("./Layouts/OrderLayout" ))
const IssuesLayout = lazy(() => import("./Layouts/IssuesLayout"))
const ServiceIssuesLayout = lazy(() => import("./Layouts/ServiceIssuesLayout"))
function App() {
    const authContext = useContext(AuthContext)
    const [role, setRole] = useState()
    const {decryption}  = useDecryption()
    const theme = createTheme({
        palette: {
            primary: {
                light: '#b0bec5',
                main: '#78909C',
                dark: '#37474F',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff7961',
                main: '#DD2C00',
                dark: '#ba000d',
                contrastText: '#000',
            },
            tertiary: {
                light: '#82a6d0',
                main: '#618ac5',
                dark: '#05142f',
                contrastText: '#000',
            },
        },
    });
    useEffect(() => {
        if(localStorage.getItem("data") !== null){
            decryption(localStorage.getItem("data"), (data) => {
                setRole(JSON.parse(data).role)
            })
        }
    })
  return (
      <BrowserRouter >
          <ThemeProvider theme={theme}>
              <div className="App">
                  <header className="App-header">
                      <LeftDrawer />
                      <Header />
                      <Suspense fallback={<Loading />}>
                          <Routes >
                              <Route exact path={"/"} element={<HomeLayout />} />
                              <Route exact path={"/login"} element={<LoginPageLayout />} />
                              <Route exact path={"/welcome"} element={<WelcomeLayout />} />
                              <Route exact path={"/login-otp"} element={<LoginByOTPLayout />} />
                              <Route exact path={"/signup"} element={<SignupLayout />} />
                              <Route exact path={"/barcode"} element={<BarcodeLayout />} />
                              <Route exact path={"/catalog"} element={<CatalogLayout />} />
                              <Route exact path={"/customer-dashboard"}>
                                  <Route exact index path={"/customer-dashboard"} element={<ProtectedCustomerRoute>
                                      <CustomerDashboardLayout />
                                  </ProtectedCustomerRoute>} />
                                  <Route exact path={"/customer-dashboard/information"} element={<ProtectedCustomerRoute>
                                      <CustomerInformationLayout />
                                  </ProtectedCustomerRoute>} />
                                  <Route exact path={"/customer-dashboard/tickets"} element={<ProtectedCustomerRoute>
                                      <TicketsLayout />
                                  </ProtectedCustomerRoute>} />
                                  <Route exact path={"/customer-dashboard/tickets/:id"} element={<ProtectedCustomerRoute>
                                      <TicketContentLayout/>
                                  </ProtectedCustomerRoute>} />
                                  <Route exact path={"/customer-dashboard/ticket-form"} element={<ProtectedCustomerRoute>
                                      <TicketFormLayout />
                                  </ProtectedCustomerRoute>} />
                                  <Route exact index path={"/customer-dashboard/issues"} element={<ProtectedCustomerRoute>
                                      <IssuesLayout />
                                  </ProtectedCustomerRoute>} />
                              </Route>
                              <Route exact path={"/certificate"} element={<CertificateLayout />} />
                              <Route exact path={"/service-login"} element={<ServiceLoginLayout />} />
                              <Route exact path={"/service-dashboard"}>
                                  <Route exact path={"/service-dashboard"} element={<ProtectedServiceRoute>
                                        <ServiceDashboardLayout />
                                    </ProtectedServiceRoute>} />
                                  <Route exact path={"/service-dashboard/issues"} element={<ProtectedServiceRoute>
                                        <ServiceIssuesLayout />
                                  </ProtectedServiceRoute>} />
                              </Route>
                              <Route exact path={"/agent-dashboard"} >
                                  <Route exact index path={"/agent-dashboard"} element={<ProtectedAgentRoute>
                                          <AgentDashboardLayout />
                                      </ProtectedAgentRoute>} />
                                  <Route exact path={"/agent-dashboard/prices"} element={<ProtectedAgentRoute>
                                            <PriceLayout />
                                      </ProtectedAgentRoute>} />
                                  <Route exact path={"/agent-dashboard/information"} element={<ProtectedAgentRoute>
                                          <AgentInformationLayout />
                                      </ProtectedAgentRoute>} />
                                  <Route exact path={"/agent-dashboard/guarantee-activation"} element={<ProtectedAgentRoute>
                                          <GuaranteeActivationLayout />
                                      </ProtectedAgentRoute>} />
                                  <Route exact path={"/agent-dashboard/guaranties-registration"} element={<ProtectedAgentRoute>
                                      <GuarantiesRegistrationLayout />
                                  </ProtectedAgentRoute>} />
                                  <Route exact path={"/agent-dashboard/review-guarantee"} element={<ProtectedAgentRoute>
                                          <GuarantiesListLayout />
                                        </ProtectedAgentRoute>} />
                                  <Route exact path={"/agent-dashboard/tickets"} element={<ProtectedAgentRoute>
                                      <TicketsLayout />
                                  </ProtectedAgentRoute>} />
                                  <Route exact path={"/agent-dashboard/tickets/:id"} element={<ProtectedAgentRoute>
                                      <TicketContentLayout/>
                                  </ProtectedAgentRoute>} />
                                  <Route exact path={"/agent-dashboard/ticket-form"} element={<ProtectedAgentRoute>
                                      <TicketFormLayout />
                                  </ProtectedAgentRoute>} />
                                  <Route exact path={"/agent-dashboard/cart"} element={<ProtectedAgentRoute>
                                      <CartLayout />
                                  </ProtectedAgentRoute>} />
                                  <Route exact path={"/agent-dashboard/orders"} element={<ProtectedAgentRoute>
                                      <ModifyOrdersLayout />
                                  </ProtectedAgentRoute>} />
                                  <Route exact path={"/agent-dashboard/order/:id"} element={<ProtectedAgentRoute >
                                      <OrderLayout />
                                  </ProtectedAgentRoute>} />
                              </Route>
                              <Route exact path={"/*"} element={<Navigate to={"/"} />} />
                          </Routes>
                      </Suspense>
                      <Footer />
                  </header>
              </div>
          </ThemeProvider>
      </BrowserRouter>
  );
}

export default App;
