import HomeBannerCard from "../../Components/HomeBannerCard/HomeBannerCard";
import {Fragment} from "react";
import classes from "./HomeBanner.module.css"
import {useMediaQuery} from "react-responsive";

const HomeBanners = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 992px)` });
    const reach_out = "Have a question or feedback? We're all ears. Click here to get in touch with our dedicated team, eager to assist you. Your voice matters at Heinrichs".substring(0, 90) + "..."
    const journey = "Dive deep into the Heinrichs story. From humble beginnings to a global brand, discover our history, values, and the people behind the magic".substring(0, 90) + "..."
    const vision = "we're not just about products; we're about possibilities. Explore our vision for the future, built on innovation, and a passion for excellence".substring(0,  90) + "..."
    const globe = "Whether you're in Berlin or Tokyo, find a Heinrichs store. Browse our global network and experience our products firsthand, no matter where you are".substring(0, 90) + "..."
    return(
        isMobile ?
        <Fragment>
            <div style={{marginTop: 30}} className={classes.container}>
                <div style={{textAlign:"center", marginTop:20 }}><h3 style={{color:"#000000"}}>Contact Us Every Where, Every Time, We are With You</h3></div>
                <div className="row" style={{margin: 20}}>
                    <div className="col-md" style={{margin: 10}}>
                        <HomeBannerCard
                            title="Reach Out & Connect"
                            content={reach_out}
                            img_url="https://heinrichsclub.com/club/assets/img/banners/contact-us.jpg"
                            img_alt=""
                        />
                    </div>
                    <div className="col-md" style={{margin: 10}}>
                        <HomeBannerCard
                            title="Our Journey & Legacy"
                            content={journey}
                            img_url="https://heinrichsclub.com/club/assets/img/banners/about-us.jpg"
                            img_alt=""
                        />
                    </div>
                    <div className="col-md" style={{margin: 10}}>
                       <HomeBannerCard
                            title="Crafting Tomorrow's Innovations"
                            content={vision}
                            img_url="https://heinrichsclub.com/club/assets/img/banners/vision-us.jpg"
                            img_alt=""
                        />
                    </div>
                    <div className="col-md" style={{margin: 10}}>
                        <HomeBannerCard
                            title="Heinrichs Around the Globe"
                            content={globe}
                            img_url="https://heinrichsclub.com/club/assets/img/banners/global-us.jpg"
                            img_alt=""
                        />
                    </div>
                </div>
            </div>
        </Fragment>:
            isTablet ?
                <Fragment>
                    <div style={{marginTop: 30}} className={classes.container}>
                        <div style={{textAlign:"center", marginTop:20 }}><h3 style={{color:"#000000"}}>Contact Us Every Where, Every Time, We are With You</h3></div>
                        <div className="row" style={{margin: 20}}>
                           <div className="row">
                               <div className="col-md-6">
                                   <HomeBannerCard
                                       title="Reach Out & Connect"
                                       content={reach_out}
                                       img_url="https://heinrichsclub.com/club/assets/img/banners/contact-us.jpg"
                                       img_alt=""
                                   />
                               </div>
                               <div className="col-md-6" >
                                   <HomeBannerCard
                                       title="Our Journey & Legacy"
                                       content={journey}
                                       img_url="https://heinrichsclub.com/club/assets/img/banners/about-us.jpg"
                                       img_alt=""
                                   />
                               </div>
                           </div>
                           <div className="row">
                               <div className="col-md-6" >
                                   <HomeBannerCard
                                       title="Crafting Tomorrow's Innovations"
                                       content={vision}
                                       img_url="https://heinrichsclub.com/club/assets/img/banners/vision-us.jpg"
                                       img_alt=""
                                   />
                               </div>
                               <div className="col-md-6" >
                                   <HomeBannerCard
                                       title="Heinrichs Around the Globe"
                                       content={globe}
                                       img_url="https://heinrichsclub.com/club/assets/img/banners/global-us.jpg"
                                       img_alt=""
                                   />
                               </div>
                           </div>
                        </div>
                    </div>
                </Fragment>:
                <Fragment>
                    <div style={{marginTop: 30}} className={classes.container}>
                        <div style={{textAlign:"center", marginTop:20 }}><h3 style={{color:"#000000"}}>Contact Us Every Where, Every Time, We are With You</h3></div>
                        <div className="row" style={{margin: 20}}>
                            <div className="col-md" style={{margin: 10}}>
                                <HomeBannerCard
                                    title="Reach Out & Connect"
                                    content={reach_out}
                                    img_url="https://heinrichsclub.com/club/assets/img/banners/contact-us.jpg"
                                    img_alt=""
                                />
                            </div>
                            <div className="col-md" style={{margin: 10}}>
                                <HomeBannerCard
                                    title="Our Journey & Legacy"
                                    content={journey}
                                    img_url="https://heinrichsclub.com/club/assets/img/banners/about-us.jpg"
                                    img_alt=""
                                />
                            </div>
                            <div className="col-md" style={{margin: 10}}>
                                <HomeBannerCard
                                    title="Crafting Tomorrow's Innovations"
                                    content={vision}
                                    img_url="https://heinrichsclub.com/club/assets/img/banners/vision-us.jpg"
                                    img_alt=""
                                />
                            </div>
                            <div className="col-md" style={{margin: 10}}>
                                <HomeBannerCard
                                    title="Heinrichs Around the Globe"
                                    content={globe}
                                    img_url="https://heinrichsclub.com/club/assets/img/banners/global-us.jpg"
                                    img_alt=""
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>

    )
}
export default  HomeBanners