import BasicCard from "../../Widget/BasicCard/BasicCard";
import {memo, useState} from "react";
import React from 'react';
import {useMediaQuery} from "react-responsive";

const LinksFooter = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const [catalogueLinks , setCatalogueLinks] = useState([
        {
            key:1,
            url:"#",
            title:"New Products"
        },{
            key:2,
            url:"#",
            title:"Technology News"
        },{
            key:3,
            url:"#",
            title:"Unboxing Videos"
        },{
            key:4,
            url:"#",
            title:"Best in New Year"
        },{
            key:5,
            url:"#",
            title:"Visions and Future"
        }
    ])

    const [usefulLinks , setUsefulLinks] = useState([
        {
            key:6,
            url:"#",
            title:"Your Account"
        },{
            key:7,
            url:"#",
            title:"Be an Agent"
        },{
            key:8,
            url:"#",
            title:"Barcode Checker"
        },{
            key:9,
            url:"#",
            title:"Awards and Rating"
        },{
            key:10,
            url:"#",
            title:"Statistics"
        }
    ])
    const [ futureLinks , setFutureLinks] = useState([
        {
            key:16,
            url:"#",
            title:"Our Stores"
        },{
            key:17,
            url:"#",
            title:"Our Agents"
        },{
            key:18,
            url:"#",
            title:"Services Co"
        },{
            key:19,
            url:"#",
            title:"Futures"
        },{
            key:20,
            url:"#",
            title:"Next Step"
        }
    ])
    const [contactLinks , setContactLinks] = useState([
        {
            key:11,
            url:"#",
            title:"About Us"
        },{
            key:12,
            url:"#",
            title:"Contact Form"
        },{
            key:13,
            url:"#",
            title:"Email: info@heinrichsclub.com"
        },{
            key: 14,
            url:"#",
            title:"Phone: +49 (152) 1356-7688"
        },{
            key:15,
            url:"#",
            title:"Ramanco GmbH VirmondstraBe 135 47877 Willich "
        }
    ])

    return(
        <div className="row container-fluid g-0" style={{paddingBottom:5, paddingTop:5, width:"100%",backgroundColor:"#90a4ae", margin:(0, "auto")}}>
            <div className={isMobile ?"col-sm-6" :"col-sm-3"} ><BasicCard key="a" title="Catalogue" content="" links={catalogueLinks}/></div>
            <div className={isMobile ?"col-sm-6" :"col-sm-3"} ><BasicCard key="b" title="Useful Link" content="" links={usefulLinks}/></div>
            <div className={isMobile ?"col-sm-6" :"col-sm-3"} ><BasicCard key="c" title="Heinrichs Future" content="" links={futureLinks}/></div>
            <div className={isMobile ?"col-sm-6" :"col-sm-3"} ><BasicCard key="d" title="Contact US" content="" links={contactLinks}/></div>
        </div>
    )
}

export default memo(LinksFooter)