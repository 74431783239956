import {Button, Card} from "@mui/material";
import {useNavigate} from "react-router";
import {memo, useCallback} from "react";

const TicketAction = (props) => {
    const navigate = useNavigate()

    const navigateToTicketForm = useCallback(() => {
        if(props.role === "agent"){
            navigate(`/agent-dashboard/ticket-form`)
        }else if(props.role === "customer"){
            navigate(`/customer-dashboard/ticket-form`)
        }else {

        }

    }, [])

    return(
        <Card sx={{marginTop:2, marginBottom:2, marginRight:"auto", marginLeft:"auto", width:"100%", padding:1}}>
            <Button variant="contained" onClick={navigateToTicketForm}>Submit A Ticket</Button>
        </Card>
        )
}
export default memo(TicketAction)