import {useEffect} from "react";
import {useNavigate} from "react-router";
import useDecryption from "../hooks/useDecryption";
import {useDispatch, useSelector} from "react-redux";
import {roleSliceActions} from "../Store/roleSlice";
import TicketAction from "../Components/TicketAction/TicketAction";
import TicketsList from "../Containers/TicketsList/TicketsList";

const TicketsLayout = () => {
    const navigate = useNavigate()
    const {decryption} = useDecryption()
    const dispatch = useDispatch()
    const roleStore = useSelector(state => state.roleSlice.role)
    useEffect(() => {
        console.log(roleStore)
        if(localStorage.getItem("data") !== null){
            let role = ""
            decryption(localStorage.getItem("data"), (data) => {
                dispatch(roleSliceActions.setRole(JSON.parse(data).role))
                role = JSON.parse(data).role

            })
            if(localStorage.getItem("data") === null || (role !== "customer" && role !== "agent")){
                navigate("/login")
            }
        }
    }, [])
    return(
        (roleStore !== "customer" && roleStore !== "agent") ? null :
        <div className="row container-fluid g-0" style={{marginTop: 70, marginBottom: 6, justifyContent:"center", width:"90%", marginRight:"auto", marginLeft:"auto"}}>
            <TicketAction role={roleStore}/>
            <TicketsList role={roleStore}/>
        </div>
    )
}
export default TicketsLayout