import {Fragment, useContext, useEffect} from "react";
import {useNavigate} from "react-router";

import useDecryption from "../hooks/useDecryption";
import {useDispatch, useSelector} from "react-redux";
import {roleSliceActions} from "../Store/roleSlice";
import GuarantiesRegistration from "../Containers/GuarantiesRegistration/GuarantiesRegistration";

const GuarantiesRegistrationLayout = () => {
    const navigate = useNavigate()
    const {decryption} = useDecryption()
    const dispatch = useDispatch()
    const roleStore = useSelector(state => state.roleSlice.role)
    useEffect(() => {
        if(localStorage.getItem("data") !== null){
            let role = ""
            decryption(localStorage.getItem("data"), (data) => {
                dispatch(roleSliceActions.setRole(JSON.parse(data).role))
                role = JSON.parse(data).role

            })
            if(localStorage.getItem("data") === null || role !== "agent"){
                navigate("/")
            }
        }
    }, [])
    return(
        <Fragment>
            {roleStore !== "agent" ? navigate("/") : <GuarantiesRegistration /> }
        </Fragment>
    )
}
export default GuarantiesRegistrationLayout