import BasicCard from "../../Widget/BasicCard/BasicCard";
import {Fragment, memo, useState} from "react";
import {useMediaQuery} from "react-responsive";

const AboutFooter = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 992px)` });
    const [aboutUs, setAboutUS] = useState("A legacy of German excellence in the world of home appliances.\n" +
        "\n" +
        "Located in the picturesque landscape of the Lower Rhine in Germany, Heinrich's stands tall as a beacon of quality, innovation, and elegance. Founded a mere 6 years ago, our brand has rapidly risen through the ranks to establish a commanding presence not only in the local market but across the globe.\n" +
        "\n" +
        "Our diverse range spans both small and large electrical appliances, catering to all your home needs.\n" +
        "Our influence isn't confined within Germany's borders. Heinrich's has a formidable presence in 24 countries, with contractual partners who vouch for the premium quality of our offerings.\n" +
        "With over 10,000 m^2 of storage space at our locations, we ensure seamless supply to numerous national and international distribution channels and trading partners.\n" +
        "Our products aren't merely tools; they are a statement of style. Designed meticulously in Germany, our appliances and their packaging radiate elegance, setting them apart in a league of their own. The vibrant palette in which they come ensures that they don't just blend into your home; they enhance its aesthetic appeal.\n" +
        "At Heinrich's, quality isn't a trait; it's our identity. Our slogan, \"Designed in Germany,\" resonates with our relentless pursuit of perfection. Every product that bears our name undergoes rigorous checks to ensure it upholds the gold standard of German quality.")
    return(
        <div className="row container-fluid g-0" style={{ paddingTop:10, paddingBottom:10, width:"100%",backgroundColor:"#b0bec5", margin:(10, "auto")}}>
            {
                isMobile ?
                    <Fragment>
                        <div className="col-sm-10"><BasicCard title="About Company" content={aboutUs} links={[]}  /></div>
                        <div className="col-sm-2">
                            <img style={{width:"100%"}} src="https://heinrichsclub.com/club/assets/img/logo/logo512.png"  alt=""/>
                        </div>
                    </Fragment>:
                    isTablet ?
                    <Fragment>
                        <div className="row"><BasicCard title="About Company" content={aboutUs} links={[]}  /></div>
                        <div className="row" style={{justifyContent:"center"}}>
                            <img style={{width:"50%"}} src="https://heinrichsclub.com/club/assets/img/logo/logo512.png"  alt=""/>
                        </div>
                    </Fragment>:
                        <Fragment>
                            <div className="col-sm-10"><BasicCard title="About Company" content={aboutUs} links={[]}  /></div>
                            <div className="col-sm-2">
                                <img style={{width:"100%"}} src="https://heinrichsclub.com/club/assets/img/logo/logo512.png"  alt=""/>
                            </div>
                        </Fragment>
            }

        </div>
    )
}
export default memo(AboutFooter)