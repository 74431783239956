import {Fragment} from "react";
import {Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {useContext} from "react";
import {DrawerContext} from "../../Contexts/DrawerContext/DrawerContext";
import HomeIcon from '@mui/icons-material/Home';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import StoreIcon from '@mui/icons-material/Store';
import QrCodeIcon from '@mui/icons-material/QrCode';
import MessageIcon from '@mui/icons-material/Message';
import PublicIcon from '@mui/icons-material/Public';
import InfoIcon from '@mui/icons-material/Info';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import UpdateIcon from '@mui/icons-material/Update';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {useNavigate} from "react-router";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import useDecryption from "../../hooks/useDecryption";
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const DrawerContent = () => {
    const drawerContext = useContext(DrawerContext)
    const navigate = useNavigate()
    const {decryption} = useDecryption()
    const closeDrawer = () => {
        drawerContext.dispatch({type:"toggleDrawer"})
    }
    const homeHandler = () => {
        navigate("/")
        drawerContext.dispatch({type:"toggleDrawer"})
    }
    const dashboardHandler = () => {
        let role = ""
        decryption(localStorage.getItem("data"), (data) => {
            role = JSON.parse(data).role
        })
        if(role === "agent"){
            navigate("/agent-dashboard", {replace:true})
        }else if(role === "customer") {
            navigate("/customer-dashboard", {replace:true})
        }else if(role === "service"){
            navigate("/service-dashboard", {replace:true})
        }else {

        }
        drawerContext.dispatch({type:"toggleDrawer"})
    }
    const signUpHandler = () => {
        if(localStorage.getItem("data") === null){
            navigate("/welcome", {replace:true})
        }else {
            let role = ""
            decryption(localStorage.getItem("data"), (data) => {
                role = JSON.parse(data).role
            })
            if(role === "agent"){
                navigate("/agent-dashboard", {replace:true})
            }else if(role === "customer") {
                navigate("/customer-dashboard", {replace:true})
            }else if(role === "service"){
                navigate("/service-dashboard", {replace:true})
            }else {

            }
        }
        drawerContext.dispatch({type:"toggleDrawer"})
    }
    const loginHandler = () => {
        if(localStorage.getItem("data") === null){
            navigate("/login", {replace:true})
        }
        else if(localStorage.getItem("data") !== null) {
            decryption(localStorage.getItem("data"), (dat) => {
                const data = JSON.parse(dat).role
                if(data === "agent"){
                    navigate("/agent-dashboard", {replace:true})
                }else if(data === "service"){
                    navigate("/service-dashboard", {replace:true})
                }else if(data === "customer"){
                    navigate("/customer-dashboard", {replace:true})
                }
            })
        }else{

        }
        drawerContext.dispatch({type:"toggleDrawer"})
    }
    const catalogHandler = () => {
        navigate("/catalog")
        drawerContext.dispatch({type:"toggleDrawer"})
    }
    const barcodeHandler = () => {
        navigate("/barcode")
        drawerContext.dispatch({type:"toggleDrawer"})
    }
    const serviceHandler = () => {
        navigate("/service-login")
        drawerContext.dispatch({type:"toggleDrawer"})
    }
    const ticketHandler = () => {
        navigate("/agent-dashboard/tickets")
        drawerContext.dispatch({type:"toggleDrawer"})
    }
    return (
        <Fragment>
            <DrawerHeader>
                <IconButton onClick={closeDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton onClick={homeHandler}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                </ListItem>
                {
                    localStorage.getItem("data") !== null ?
                        <ListItem key={Math.random()} disablePadding>
                            <ListItemButton onClick={dashboardHandler}>
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                        </ListItem>: null
                }

                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton onClick={signUpHandler}>
                        <ListItemIcon>
                            <GroupAddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sign Up" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton onClick={loginHandler}>
                        <ListItemIcon>
                            <LockOpenIcon />
                        </ListItemIcon>
                        <ListItemText primary="Login" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton onClick={catalogHandler} >
                        <ListItemIcon>
                            <StoreIcon />
                        </ListItemIcon>
                        <ListItemText primary="Catalog" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton onClick={barcodeHandler}>
                        <ListItemIcon>
                            <QrCodeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Barcode Checker" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton onClick={ticketHandler}>
                        <ListItemIcon>
                            <MessageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Send A Ticket" />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton >
                        <ListItemIcon>
                            <PublicIcon />
                        </ListItemIcon>
                        <ListItemText primary="Social Networks" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton >
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="About Us" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton >
                        <ListItemIcon>
                            <PermPhoneMsgIcon />
                        </ListItemIcon>
                        <ListItemText primary="Contact Us" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton >
                        <ListItemIcon>
                            <UpdateIcon />
                        </ListItemIcon>
                        <ListItemText primary="Future Of Heinrichs" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton >
                        <ListItemIcon>
                            <StorefrontIcon />
                        </ListItemIcon>
                        <ListItemText primary="Our Stores" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton >
                        <ListItemIcon>
                            <SupportAgentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Our Agents" />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem key={Math.random()} disablePadding>
                    <ListItemButton onClick={serviceHandler}>
                        <ListItemIcon>
                            <WorkspacePremiumIcon />
                        </ListItemIcon>
                        <ListItemText primary="Service Company" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Fragment>
    )
}
export default DrawerContent