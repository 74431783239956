import {Card, TextField, Typography} from "@mui/material";
import {Fragment} from "react";

const TicketContentRow = (props) => {
    return(
        <Fragment>
            <Card sx={{minHeight: 200, marginBottom:2, marginRight: "auto", marginLeft: "auto", width: "100%", padding: 1, backgroundColor : props.userId === props.rowContent.writer_id ? "#eae9e9" : "#8c8989"  }}>
                <TextField
                    style={{width:"90%", marginTop:20}}
                    sx={{ textarea: { color: props.userId === props.rowContent.writer_id ? "#030303" : "#ffffff" } }}
                    label={props.rowContent.date}
                    id="standard-size-small"
                    defaultValue={props.rowContent.content}
                    size="normal"
                    multiline
                    InputProps={{readOnly: true, disableUnderline: true, autoFocus:false}}
                />
            </Card>
        </Fragment>

    )
}
export default TicketContentRow
