import {Fragment} from "react";
import TopAppBar from "../../Components/TopAppBar/TopAppBar";

const Header = () => {

    return(
        <Fragment>
            <TopAppBar position="fixed"/>
        </Fragment>
    )
}
export default Header